var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.blok.slides.length > 0)?_c('div',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}]},[(_vm.blok.isMobileSlider)?_c('div',{staticClass:"md:hidden w-full"},[_c('SfCarousel',{staticClass:"container px-2",attrs:{"settings":{
        type: 'carousel',
        rewind: true,
        perView: 1,
        gap: 10,
        breakpoints: {
          768: {
            perView: 1,
          },
        },
      }}},_vm._l((_vm.blok.slides),function(childrenBlok){return _c('SfCarouselItem',{key:childrenBlok._uid + 'mobile'},[_c('StoryblokComponent',{attrs:{"blok":childrenBlok}})],1)}),1)],1):_vm._e(),_vm._v(" "),_c('div',{class:[
      _vm.blok.slides.length === 5 && "xl:grid-cols-5",
      _vm.blok.slides.length === 4 && "xl:grid-cols-4",
      _vm.blok.slides.length === 3 && "xl:grid-cols-3",
      _vm.blok.slides.length === 2 && "xl:grid-cols-2",
      _vm.blok.slides.length === 1 && "xl:grid-cols-1",
      _vm.blok.isMobileSlider && "hidden md:grid md:grid-cols-2 gap-8",
      !_vm.blok.isMobileSlider && "grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 px-2 md:px-0" ]},_vm._l((_vm.blok.slides),function(childrenBlok){return _c('div',{key:childrenBlok._uid,staticClass:"flex justify-center w-full"},[_c('StoryblokComponent',{attrs:{"blok":childrenBlok}})],1)}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }